<template>
  <v-dialog v-model="dialog" persistent max-width="30%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar"
                ? "Editar Valor Fixo"
                : "Cadastrar Valor Fixo"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row align="center">
            <v-col lg="12" md="412" sm="12" cols="12">
              <BaseSelect
                prepend-inner-icon="mdi-file-table-box-multiple-outline"
                clearable
                single-line
                label="Segmento"
                item-text="descricao"
                item-value="id_band"
                :rules="[rules.required]"
                :filters="{ id_band: '2,4' }"
                service="sistemaService.tipoBandeiras"
                v-model="formValorFixo.id_band"
                :isCompany="true"
              />
            </v-col>
            <v-col lg="12" md="412" sm="12" cols="12">
              <BaseSelect
                single-line
                label="Tipo Meta"
                item-text="tipo_meta"
                item-value="id_tipo_meta"
                service="campanhaService.tipoMeta"
                v-model="formValorFixo.id_tipo_meta"
                :rules="[rules.required]"
                clearable
              >
              </BaseSelect>
            </v-col>
            <v-col lg="12" md="412" sm="12" cols="12">
              <vuetify-money
                v-model.number="formValorFixo.valor_max"
                label="Valor Máximo"
                valueWhenIsEmpty="0"
                :options="optionsVuetifyMoney"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? put() : send()"
          >{{ labelBtn }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import BaseSelect from "@/components/shared/BaseSelectService";
import campanha from "@/services/http/campanhaService";

export default {
  name: "ModalValorFixo",
  mixins: [rules],

  components: {
    BaseSelect
  },

  props: {
    tipoMetaBonus: {
      type: Object,
      default: () => ({})
    },
    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    }
  },

  data() {
    return {
      optionsVuetifyMoney: {
        locale: "pt-BR",
        prefix: "R$",
        length: 11,
        precision: 2
      },

      formValorFixo: {}
    };
  },

  watch: {
    tipoMetaBonus(value) {
      this.formValorFixo = value;
    },
    labelBtn(value) {
      this.locked = value == "Editar" ? true : false;
    }
  },

  methods: {
    close() {
      this.$emit("close");
      this.$refs.form.reset();
    },
    async send() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await campanha()
          .tipoMetaBonus()
          .store(this.formValorFixo, {
            notification: true,
            menssage: "Meta Bonus Cadastrada com sucesso!"
          });
      }
      this.close();
    },
    async put() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await campanha()
          .tipoMetaBonus(this.formValorFixo.id_bonus_tipo_meta)
          .update(this.formValorFixo, {
            notification: true,
            menssage: "Meta Bonus Editada com sucesso!"
          });
      }
      this.close();
    }
  }
};
</script>
