<template>
  <div>
    <BaseTable
      :headers="headers"
      :loading="loading"
      :paginate="true"
      :items="items"
      sort-by="tipo-meta-bonus"
      class="table-shadow"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Valores Fixos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="483"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <Filtro @selectedFilters="fetchMetaBonus((filters = $event))" />
        </v-toolbar>
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <v-btn
          icon
          v-can-access="344"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          v-can-access="486"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </template>
    </BaseTable>
    <ModalValorFixo
      :tipoMetaBonus="tipoMetaBonus"
      :dialog="dialog"
      :labelBtn="labelBtn"
      @close="refresh()"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable";
import BaseSegmento from "@/components/shared/BaseSegmento";
import Filtro from "./Filtro";
import ModalValorFixo from "./ModalValorFixo";
import currencyMixin from "@/mixins/currencyMixin";
import dateMixin from "@/mixins/dateMixin";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import campanha from "@/services/http/campanhaService";

export default {
  name: "Tabela",

  components: {
    BaseTable,
    BaseSegmento,
    Filtro,
    ModalValorFixo,
    RegisterBottom
  },
  filters: {},

  mixins: [currencyMixin, dateMixin],

  data() {
    return {
      dialog: false,
      tipoMetaBonus: {},
      labelBtn: "",
      filters: {},
      headers: [
        { text: "Segmento", value: "id_band" },
        { text: "Tipo Meta", value: "tipo_meta" },
        { text: "Valor Máximo", value: "valor_max", align: "right" },
        { text: "Ações", value: "acoes" }
      ],
      items: [],
      loading: false
    };
  },

  methods: {
    editItem(item) {
      this.tipoMetaBonus = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    async fetchMetaBonus(filters) {
      this.loading = true;
      const { data } = await campanha()
        .tipoMetaBonus()
        .show({
          per_page: -1,
          ativo: "S",
          tipoMetaBonus: "S",
          ...filters
        });
      this.items = data.data;
      this.loading = false;
    },
    refresh() {
      this.dialog = false;
      this.loading = true;
      this.fetchMetaBonus(this.filters);
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deletar Valor Fixo",
          "Deseja deletar Valor Fixo?"
        );
        campanha()
          .tipoMetaBonus(item.id_bonus_tipo_meta)
          .delete(
            {},
            {
              Notification: true,
              menssage: " Tipo Meta Bonus Deletado com sucesso!"
            }
          );
        this.refresh();
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar valor fixo"
        });
      } finally {
        this.fetchMetaBonus(this.filters);
      }
    }
  },
  async mounted() {
    await this.fetchMetaBonus();
  }
};
</script>
